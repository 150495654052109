import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0,13,2,9,3,6];

export const dictionary = {
		"/": [~14],
		"/admin": [58,[13]],
		"/admin/crawl": [~59,[13]],
		"/admin/crawl/[job]": [~60,[13]],
		"/admin/waitlist": [~61,[13]],
		"/(public)/auth/fail": [53,[12]],
		"/(public)/auth/verify": [54,[12]],
		"/(public)/contact": [55,[12]],
		"/integrations/storyblok/authorize": [~62],
		"/(public)/login": [~56,[12]],
		"/(public)/logout": [57,[12]],
		"/(protected)/me": [~43,[2,9]],
		"/(protected)/me/invite": [~44,[2,9]],
		"/(protected)/me/invite/[inviteId]": [~45,[2,9]],
		"/(protected)/me/organisations": [~46,[2,9]],
		"/(protected)/me/organisations/[orga]": [~47,[2,9,10]],
		"/(protected)/me/organisations/[orga]/billing": [~48,[2,9,10]],
		"/(protected)/me/organisations/[orga]/collaborators": [~49,[2,9,10]],
		"/(protected)/me/settings": [50,[2,9,11]],
		"/(protected)/me/settings/appearance": [51,[2,9,11]],
		"/(protected)/me/settings/notifications": [~52,[2,9,11]],
		"/(protected)/[agent]": [~15,[2,3],[,4]],
		"/(protected)/[agent]/audiences": [~16,[2,3],[,4]],
		"/(protected)/[agent]/changes": [~17,[2,3],[,4]],
		"/(protected)/[agent]/cohorts": [~18,[2,3],[,4]],
		"/(protected)/[agent]/cohorts/[id]": [~19,[2,3],[,4]],
		"/(protected)/[agent]/goals": [~20,[2,3],[,4]],
		"/(protected)/[agent]/insights": [~21,[2,3],[,4]],
		"/(protected)/[agent]/keywords": [~22,[2,3],[,4]],
		"/(protected)/[agent]/migrate": [~23],
		"/(protected)/[agent]/pages": [~24,[2,3],[,4]],
		"/(protected)/[agent]/pages/[id]": [~25,[2,3],[,4]],
		"/(protected)/[agent]/personas": [~26,[2,3],[,4]],
		"/(protected)/[agent]/settings": [27,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/billing": [~28,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/change": [~29,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/success": [~30,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/collaborators": [~31,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/danger": [~32,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/guides": [~33,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/notifications": [~34,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/setup": [~35,[2,3,5],[,4]],
		"/(protected)/[agent]/strategies": [~36,[2,3],[,4]],
		"/(protected)/[agent]/tests": [~37,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]": [~38,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]/edit": [~39],
		"/(protected)/[agent]/tests/[id]/goals": [~40,[2,3],[,4]],
		"/(protected)/[agent]/welcome": [~41,[2,3],[,4]],
		"/(protected)/[agent]/welcome/[step]": [~42,[2,3,8],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';